@mixin central-buttons {
  a {
    padding: 0;
    margin: 0;
    overflow: hidden;
  }
  #central-buttons {
    [role="button"] {
      height: 14.5rem;
      width: 50%;
      cursor: pointer;
      &#prepay-button {
        background: $green;
        img {
          height: 7.2rem;
        }
      }
      &#outage-button {
        background: $blue;
        img {
          height: 9rem;
        }
      }
      .central-buttons-img {
        width: 25%;
        text-align: right;
        padding-right: 4rem;
      }
      .central-buttons-text {
        width: 70%;
        h4 {
          font-weight: bold;
          font-size: 36px;
          line-height: 42px;
          margin: 0;
          color: $white;
        }
        p {
          font-weight: 500;
          font-size: 24px;
          line-height: 28px;
          margin: 0;
          color: $white;
        }  
      }
    }
  }
  @media (max-width: 1279px) {
    #central-buttons {
      flex-wrap: wrap;
      [role="button"] {
        width: 100%;
        min-height: 11rem;
        .central-buttons-img {
          width: 35%;
        }
        .central-buttons-text {
          width: 65%;
        }    
      }
    }
  }
  @media (max-width: 659px) {
    #central-buttons {
      [role="button"] {
        height: 7.6rem;
        &#prepay-button {
          img {
            height: 3.8rem;
          }
        }
        &#outage-button {
          img {
            height: 4.7rem;
          }
        }
        .central-buttons-img {
          width: 25%;
        }
        .central-buttons-text {
          width: 75%;
          h4 {
            font-size: 24px;
            line-height: 25px;
          }
          p {
            font-size: 18px;
            line-height: 20px;
          }  
        }    
      }
    }
  }
  @media (max-width: 449px) {
    #central-buttons {
      [role="button"] {
        height: 7.6rem;
        &#prepay-button {
          img {
            height: 3.8rem;
          }
        }
        &#outage-button {
          img {
            height: 4.7rem;
          }
        }
        .central-buttons-text {
          h4 {
            font-size: 18px;
            line-height: 21px;
          }
          p {
            font-size: 12px;
            line-height: 14px;
          }    
        }
      }
    }
  }
}