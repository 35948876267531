@mixin homepage {
  #homepage {
    #main-wrapper {
      #page-header {
        position: absolute;
        top: 0;
        z-index: 3;
        opacity: 0.7;
      }
      #navigation-header {
        position: absolute;
        top: 56px;
        z-index: 3;
        opacity: 0.8;
      }  
    }
  }
}