@mixin main-wrapper {
  #main-wrapper {
    max-width: 1440px;
    margin: 0 auto;
    #wrap-content {
      padding-bottom: 22.1rem; // height of footer
    }
    @media (max-width: 1279px) {
      #main-body {
        #wrap-content {
          padding-bottom: 7.2rem; // height of footer
        }
      }
    } 
    @media (max-width: 659px) {
      #main-body {
        #wrap-content {
          padding-bottom: 12rem; // height of footer
        }
      }
    }
  }
}