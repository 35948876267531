.container-flex {
  display: flex;
}
.container-vertical {
  flex-direction: column;
}
.container-flex-wrap {
  flex-wrap: wrap;
}
.container-right-align-items {
  justify-content: flex-end;
}
.container-center-align-items {
  justify-content: center;
}
.container-space-between-items {
  justify-content: space-between;
}
.container-space-around-items {
  justify-content: space-around;
}
.container-space-evenly-items {
  justify-content: space-evenly;
}
.container-top-align-items {
  align-items: flex-start;
}
.container-bottom-align-items {
  align-items: flex-end;
}
.container-vertical-stretch-items {
  align-items: stretch;
}
.container-vertical-center-items {
  align-items: center;
}
.container-vertical-center-baseline-items {
  align-items: baseline;
}
.container-top-align-wrap-items {
  align-content: flex-start;
}
.container-bottom-align-wrap-items {
  align-content: flex-end;
}
.container-center-align-wrap-items {
  align-content: center;
}
.container-space-between-align-wrap-items {
  align-content: space-between;
}
.container-space-around-align-wrap-items {
  align-content: space-around;
}
.container-stretch-align-wrap-items {
  align-content: stretch;
}
.item-align-top {
  align-self: flex-start;
}
.item-align-bottom {
  align-self: flex-end;
}
.item-align-center {
  align-self: center;
}
.item-align-baseline {
  align-self: baseline;
}
.item-align-stretch {
  align-self: stretch;
}
.item-flex-1 {
  flex-grow: 1;
}
.item-flex-2 {
  flex-grow: 2;
}
.item-flex-3 {
  flex-grow: 3;
}
.item-flex-4 {
  flex-grow: 4;
}
.item-flex-5 {
  flex-grow: 5;
}
.item-flex-6 {
  flex-grow: 6;
}