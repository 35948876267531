@mixin prepay {
  #prepay {
    #pre-pay-description {
      padding: 0 0 2.4rem;
      font-size: 16px;
      line-height: 24px;
      color: #242424;
      border-bottom: 1px solid #D8D8D8;
    }
  }
  #contacts-header {
    color: #989799;
    font-size: 18px;
  }
  iframe#prepay {
    width: 100%;
    max-width: 1200px;
    height: 200rem;
    margin: 50px 10px;
  }

}
