@mixin login {
  #login-page {
    #main-body {
      background: $grayLight2;
      #wrap-content {
        padding-bottom: 2.4rem; // height of footer  
      }
    }
    #login-header-logo {
      img {
        height: 6.1rem;
        margin: 15vmin 0 4.8rem;
      }
    }
    #log-in-with-google {
      width: 32rem;
      height: 4.8rem;
      background: $blue2;
      margin-bottom: 2.2rem;
      cursor: pointer;
      #google-logo {
        height: 4.8rem;
        width: 4.8rem;
        background: $blueDark;
        img{
          height: 2rem;
        }
      }
      #google-text {
        font-weight: bold;
        font-size: 1.4rem;
        line-height: 1.6rem;
        letter-spacing: 0.5px;
        text-transform: uppercase;
        color: $white;
        padding-left: 2.4rem;
      }
    }
    #or {
      font-size: 1.6rem;
      line-height: 2.4rem;
      color: $blackLight;
      margin-bottom: 1.6rem;
    }
    #login-form {
      .input-container {
        position: relative;
        input {
          text-indent: 4.8rem;
          height: 4.8rem;
          width: 28rem;
          margin-bottom: 1.6rem;
          color: $gray2;
          font-size: 1.6rem;
          line-height: 2.0rem;
        }
        .input-icon {
          position: absolute;
          top: 1.8rem;
          left: 1.6rem;
        }    
      }
      #input-error {
        color: $red;
        font-size: 1.4rem;
        line-height: 1.8rem;
        margin-top: -1.2rem;
        &.hidden {
          display: none;
        }
      }
      #login-button {
        height: 4.8rem;
        width: 12.8rem;
        background: $blue;
        border-radius: .8rem;
        border: none;
        font-weight: bold;
        font-size: 1.6rem;
        line-height: 4.8rem;
        color: $white;
        text-align: center;
        letter-spacing: 0.5px;
        text-transform: uppercase;
        margin: 1.6rem 0 4.8rem;
        box-shadow: -.2rem .4rem .4rem 0 rgba(0,0,0,0.15);
        align-items: center;
      }
    }
    #bottom-links {
      a {
        font-weight: bold;
        font-size: 1.6rem;
        line-height: 1.6rem;
        letter-spacing: 0.5px;
        text-decoration-line: underline;
        text-transform: uppercase;
        color: $blue;
        margin: 0 0 4.8rem;
        cursor: pointer;
      }
      #pipe-separator {
        height: 1.6rem;
        border: 1px solid $grayDark;
        margin: 0 1.6rem 4.8rem;
      }  
    }
    #terms {
      font-size: 1.2rem;
      line-height: 1.8rem;
      a {
        color: $blue;
        text-decoration-line: underline;
        cursor: pointer;
      }
    }
    #login-footer {
      width: 100%;
      position: absolute;
      bottom: 0;
      div {
        height: 2.4rem;
        &#blue-box {
          width: 33.33%;
          background: $blue2;
        }
        &#green-box {
          width: 33.34%;
          background: $green;
        }
        &#grey-box {
          width: 33.33%;
          background: $gray2;
        }
      }
    }
    @media (max-width: 44.9rem) {
      #wrap-content {
        padding-bottom: 1.2rem; // height of footer
      }
      #log-in-with-google {
        width: 30rem;
      }
      #login-footer {
        div {
          height: 1.2rem;
        }
      }
    }
  }
}