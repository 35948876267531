@mixin subpage-wrapper {
  #subpage-wrapper {
    #prefs-gui {
      overflow: hidden;
      border: none;
    }
    #left-panel {
      width: 37rem;
    }
    #right-panel {
      width: calc(100vw - 488px);
      padding-right: 20rem;
      &.full-width {
        padding-right: 0;
      }  

    }
    #center-panel {
      // width: calc(100vw - 488px);
      width: 100%;
      margin:0 auto;
      text-align: center;
    }
  }
  @media (max-width: 1279px) {
    #subpage-content {
      flex-direction: column;
      justify-content: center;
      #left-panel {
        width: 100%;
        padding: 0;
        #subpage-sidebar {
          padding: 0;
          flex-direction: row;
          .side-bar-link {
            width: auto;
            height: auto;
            line-height: 2.4rem;
            padding: 1.6rem;
            flex-grow: 1;
            text-align: center; 
            border-right: .5px dotted $grayLight;
            &:last-child {
              border-right: none;
            }
          }
        }
      }
      #right-panel {
        width: 100%;
        padding: 0 8rem;
      }
    }
  }
  @media (max-width: 659px) {
    #subpage-wrapper {
      #subpage-banner {
        h1 {
          font-size: 2.4rem;
          line-height: 2.1rem;
          margin-bottom: 1.6rem;
        }
        h6 {
          font-size: 18px;
          line-height: 21px;
        }
      }

      #left-panel {
        #subpage-sidebar {
          flex-direction: column;
          .side-bar-link {
            border-right: none;
          }
        }
      } 
      #right-panel {
        width: 100%;
        padding: 1.6rem;
      }
    }
  }
  @media (max-width: 449px) {
    #subpage-wrapper {
      #subpage-banner {
        text-align: center;
        padding: 0 1.6rem;
      }
      #subpage-content {
        #left-panel {
          #subpage-sidebar {
            flex-direction: column;
            .side-bar-link {
              border-right: none;
            }
          }
        }
      }
    }
  }
}