@mixin information {
  #information {
    h2 {
      font-weight: bold;
      font-size: 36px;
      line-height: 42px;
      color: $gray2;
    }
    h3 {
      font-weight: bold;
      font-size: 24px;
      line-height: 32px;
      color: $gray2;
      margin: 8px 0;
    }
    h6 {
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      color: $grayDark;
      margin: 8px 0;
    }
    button {
      width: 17.5rem;
      height: 4.8rem;
      border: 2px solid $gray2;
      background: $gray2;
      box-sizing: border-box;
      border-radius: 8px;
      text-align: center;
      letter-spacing: 0.5px;
      text-transform: uppercase;
      color: $white;
      /* font-weight: bold; */
      font-size: 16px;
      line-height: 16px;
    }
    .heroImage {
      margin: 0 0 2rem 0;
    }
    .info-section {
      width: 50%;
      padding: 6.6rem 5rem;
      #top-left-info {
        margin-bottom: 4rem;
      }
      .left-info-section {
        p {
          font-size: 16px;
          line-height: 24px;
          color: $charcoal;
        }
        img {
          height: 16.1rem;
          margin-right: 4.5rem;
        }
      }
      &#right-info {
        p {
          color: $grayDark;
          margin: 0 0 3.6rem;
          &#first-p {
            font-size: 24px;
            line-height: 32px;
          }
          &#second-p {
            font-size: 20px;
            line-height: 28px;
          }
        }
        a {
          color: $blue;
          font-size: 20px;
          line-height: 28px;
          cursor: pointer;
          text-decoration: underline;
        }
        button {
          background: $blue;
          border: 1px solid $blue;
        }
      }
    }
  }
  @media (max-width: 1279px) {
    #information {
      flex-direction: column-reverse;
      justify-content: center;
      flex-wrap: wrap;
      padding: 6.6rem 5rem;
      .info-section {
        width: 100%;
        padding: 0;
        &#right-info {
          text-align: center;
          margin-bottom: 5.6rem;
          button{
            align-self: center;
          }  
        }
      }
    }
  }
  @media (max-width: 659px) {
    #information {
      #top-left-info {
        flex-wrap: wrap;
        flex-direction: column;
        margin: 0;
      }
      .info-section {
        .left-info-section {
          img {
            margin: 0;
          }
        }  
      }
      #bottom-left-info {
        flex-wrap: wrap;
        flex-direction: column;
        .left-info-section {
          img {
            margin: 4rem 0 2rem;
          }
          p {
            width: 90%;
          }
        }
      }  
    }
  }
  @media (max-width: 449px) {
    #information {
      padding: 3.2rem;
      .info-section {
        padding: 0;
        &#right-info {
          #first-p {
            font-size: 16px;
            line-height: 22px;
          }
          #second-p {
            font-size: 12px;
            line-height: 20px;
          }
          a {
            font-size: 12px;
            line-height: 20px;
          }
        }
        .left-info-section {
          p {
            font-size: 12px;
            line-height: 18px;
          }
        }
      }
    }
  }
}