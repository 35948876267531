// variables
$black: #000;
$blackLight: #242424;
$blue: #007FAD;
$blue2: #4285F4;
$blueDark: #2E5DAA;
$charcoal: #242424;
$gray: #979797;
$gray2: #536372;
$grayDark: #36434E;
$grayLight: #BABABA;
$grayLight2: #EAECEE;
$green: #7AB542;
$red: #C50017;
$white: #FFF;