@mixin page-header {
  #page-header {
    width: 100%;
    height: 5.6rem;
    background: $black;
    .page-header-link {
      text-align: center;
      margin: 0 2.3rem;
      cursor: pointer;
      a {
        font-size: 1.2rem;
        line-height: 1.4rem;
        color: $white;
      }
    }
  }
  @media (max-width: 1279px) {
    #page-header {
      display: none;
    }
  }
}
