body,
html,
#root,
#root > div,
#root > div > div,
.app {
  // keeps .app at 100% of window height
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  position: relative;
}

* {
  box-sizing: border-box;
}
