@mixin drop-down {
  .dropdown-container {
    position: relative;
    cursor: pointer;
    padding-right: 3rem;
    width: 15rem;
    height: 4.8rem;
    background: $blue;
    color: $white;
    width: 20.4rem;
    // font-weight: bold;
    line-height: 4.8rem;
    letter-spacing: 0.5px;
    text-align: left;
    text-transform: uppercase;
    border-bottom: .5px dotted $grayLight;
    text-align: right;
    display: inline-block;
    .arrow-container {
      position: absolute;
      top: 2rem;
      left: 2rem;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      &.up {
        border-bottom: 5px solid $white;
      }
      &.down {
        border-top: 5px solid $white;
      }
    }
    .drop-down-list {
      background: $white;
      color: $black;
      position: absolute;
      top: 4.8rem;
      left: 0;
      width: 100%;
      list-style: none;
      padding: 0;
      margin: 0;
      box-shadow: 0px 4px 16px rgba(36, 36, 36, 0.16);
      &.hidden {
        display: none;
      }
      li {
        padding-right: 3rem;
        width: 100%;
        background: $white;
        border-bottom: .5px dotted $grayLight;
      }
    }
  }
}