// import all components here
@import './Homepage';
@import './Login';
@import './AlertsManagement';
@import './PrePay';
@import './Tools';

@include homepage();
@include login();
@include alerts-management();
@include prepay();
@include tools();

#main-body {
  min-height: 100vh;
  position: relative;
  #wrap-content {
    height: 100%;
  }
}

a, a:hover {
  text-decoration: none;
}
