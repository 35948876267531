@mixin carousel {
  #carousel {
    width: 100%;
    position: relative;
    overflow: hidden;
    #carousel-img {
      width: 100%;
      display: block;
    }
    #carousel-radio-buttons {
      width: 5rem;
      height: 1rem;
      position: absolute;
      bottom: 2rem;
      left: 50%;
      margin-left:-25px;
      .radio-button {
        width: 1rem;
        height: 1rem;
        background: rgba(255, 255, 255, 0.4);
        border-radius: .5rem;
        cursor: pointer;
        &.active-radio {
          background: $white;
        }
      }
    }  
  }
}