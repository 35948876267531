@mixin tools {
  .iframeHolder {
    position: relative;
    left: 0;
    top: 0;
    width: 600px;
    height: auto;
    margin-bottom: 100px;
    
    @include clearfix();
    
  }
  #php-form {
    height: 560px;
    width: 600px;
  }
  #php-form-long {
    width: 100%;
    height: 110rem;
  }
}