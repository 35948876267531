@mixin navigation-header {
  #navigation-header {
    width: 100%;
    height: 9rem;
    background: $white;
    .navigation-header-link {
      text-align: center;
      margin: 0 2.5rem;
      cursor: pointer;
      a {
        color: $grayDark;
        font-weight: 500;
        font-size: 1.6rem;
        line-height: 1.9rem;
        &:hover {
          color: $gray;
          text-decoration: underline;
        }
      }
    }
    #navigation-header-logo {
      width: 14.3rem;
      margin-left: 5rem;
    }
  }
  @media (max-width: 1279px) {
    #navigation-header {
      display: none;
    }
  }
}