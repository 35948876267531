@mixin mobile-header {
  #mobile-header {
    display: none;
    background: $white;
    width: 100%;
    height: 6rem;
    #header-logo-mobile {
      width: 30%;
      height:100%;
      padding-left: 2rem;
      img {
        width: 9.6rem;
      }
    }
    button {
      background: none;
      border: none;
    }
    #mobile-header-links {
      width: 55%;
      padding: 0 5rem;
    }
    .mobile-header-social-link {
      max-width: 15rem;
      img {
        height: 2.1rem;
        color: $gray2;
      }
    }
    #mobile-header-menu {
      width: 5%;
      padding-right: 2rem;
      #mobile-header-menu-button {
        height: 2.4rem;
      }
    }
  }
  #mobile-header-menu-dropdown {
    position: absolute;
    top: 6rem;
    right: 0;
    background: $white;
    width: 37.5rem;
    height: 100%;
    padding: 1.6rem 3.7rem;
    z-index: 3;
    a {
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      text-align: right;
    }
    #mobile-menu-dropdown-top-links {
      margin-bottom: 2.6rem;
      a{
        color: $blue;
      }
    }
    #mobile-menu-dropdown-bottom-links {
      a{
        color: $grayDark;
      }
    }
    li {
      margin-bottom: 2.6rem;
      list-style: none;
    }
  }
  @media (min-width: 1280px) {
    #mobile-header-menu-dropdown {
      display: none;
    }
  }
  @media (max-width: 1279px) {
    #mobile-header {
      display: flex;
    }
    #mobile-header-menu-dropdown {
      width: 50%;
    }
  }
  @media (max-width: 659px) {
    #mobile-header-menu-dropdown {
      width: 100%;
    }
  }
}
