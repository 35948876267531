@mixin footer {
  #app-footer {
    width: 100%;
    background: $grayLight2;
    padding: 3.7rem 0 2.5rem 17.4rem;
    position: absolute;
    bottom: 0;
    #footer-links {
      margin-bottom: 2.5rem;
      .footer-link{
        color: $blue;
        font-size: 12px;
        line-height: 14px;
        margin: .4rem 0;
      }
      h6 {
        font-weight: bold;
        margin: 0;
      }
      a{
        cursor: pointer;
      }
    }
    #footer-mobile-links {
      display: none;
    }
    #copyright {
      p {
        font-size: 10px;
        line-height: 12px;
        color: $grayDark;
      }
    }
  }  
  @media (max-width: 1279px) {
    #app-footer {
      padding: 0;
      text-align: center;
      height: 7.2rem;
      justify-content: center;
      #footer-mobile-links {
        display: flex;
        justify-content: space-between;
        text-align: center;
        font-weight: bold;
        .footer-link {
          flex-grow: 1;
          font-size: 12px;
          color: $blue;
          height: 2.4rem;
        }
      }
      #footer-links {
        display: none;
      }  
    }
  }
  @media (max-width: 659px) {
    #app-footer {
      height: 12rem;
      #footer-mobile-links {
        display: flex;
        flex-direction: column;
      }
    }
  }
}