@mixin subpage-banner {
  #subpage-banner {
    width: 100%;
    background: $green;
    height: 14.5rem;
    padding: 0 8rem;
    h1 {
      font-weight: bold;
      font-size: 36px;
      line-height: 42px;
      color: $white;
      margin: .6rem 0;
      width: 100%;
    }
    h6 {
      font-weight: 500;
      font-size: 24px;
      line-height: 28px;
      color: $white;
      margin: 0;
      width: 100%;
    }
  }
}