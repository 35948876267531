@mixin alerts-management {
  #alerts-management {
    #alerts-management-description {
      padding: 0 0 2.4rem;
      font-size: 16px;
      line-height: 24px;
      color: #242424;
      border-bottom: 1px solid #D8D8D8;
    }
    .account-drop-down {
      margin: 2.4rem 0;
      span {
        margin-right: 2.4rem;
        color: #36434E;
        font-weight: 500;
        font-size: 1.6rem;
        line-height: 1.9rem;
      }
    }
  }
  #contacts-header {
    color: #989799;
    font-size: 18px;
  }
  iframe#prefs-gui {
    width: 100%;
    max-width: 64rem;
    height: 121rem;
    margin-bottom: 100px;
  }

}
