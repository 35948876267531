@mixin subpage-sidebar {
  #subpage-sidebar {
    width: 100%;
    height: 100%;
    padding: 5.6rem 8rem;
    .side-bar-link {
      height: 4.8rem;
      width: 30.4rem;
      font-weight: bold;
      font-size: 14px;
      line-height: 4.8rem;
      letter-spacing: 0.5px;
      text-align: left;
      text-transform: uppercase;
      color: $blue;
      border: none;
      border-bottom: .5px dotted $grayLight;
      padding-left: 1.6rem;
      &.active-nav {
        background: $blue;
        color: $white;
      }
    }
  }
}