// mixins
@mixin clearfix {
    clear:both;
    &:before,
    &:after {
      content: "";
      display: table;
    }
  
    &:after { clear: both; }
  
    & { zoom: 1; }
  }